// @flow
import * as React from "react";
import { connect } from "react-redux";
import {
  UICoreBox,
  UICoreButton,
  UICoreModal,
  UICoreMultiSelectDropdown,
  UICoreText
} from "../../../../../Component/UICore";
import { Scrollbars } from "react-custom-scrollbars";
import {
  update_outcome_display_logic,
  update_outcome_page,
  set_current_editing_question,
  saveToBackend
} from "../../../../FormBuilderPage/States/actions";
import { type ToutcomeDisplayLogic } from "../../../../../FlowTypes/outcomeDisplayLogicTypes";
import { type questionIDType } from "../../../../WispformTypings";
import formConfiguration, {
  QuestionTypeEnum,
  type questionType
} from "../../../../QuestionTypes";
import {
  insertIfNonExist,
  nonnullArray,
  removeIfExist
} from "../../../../../Library/Primitives/Array";
import {
  getOutcomePageContentConfig,
  getOutcomes
} from "../../../States/selectors";
import { type TOutcomeData } from "../../../../../Component/GenericFormContent/type";
import {
  isEmptyArray,
  isNonEmptyArray,
  toArray,
  toUnNullable
} from "../../../../../Library/Util";
import { type TGenericContentConfig } from "../../../../../Component/GenericFormContent/type";
import uniqid from "uniqid";

type Props = {|
  question: questionType,
  outcomes: Array<TOutcomeData>,
  outcomePageConfig: TGenericContentConfig,
  saveToBackend: () => void,
  updateOutcomeDisplayLogic: (
    questionID: questionIDType,
    updatedOutcomeDisplayLogic: ToutcomeDisplayLogic
  ) => void,
  updateOutcomePage: (pageConfig: TGenericContentConfig) => void,
  set_current_editing_question: (string | number) => void
|};
type State = {|
  showModal: boolean
|};

class OutcomeMapping extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  _getOutcomeLogic = (): ToutcomeDisplayLogic => {
    if (this.props.question.outcomeDisplayLogic) {
      return this.props.question.outcomeDisplayLogic;
    } else {
      return {
        choiceOutcomesMapping: {}
      };
    }
  };

  _handleItemClick = (
    outcomeID: string,
    isAlreadySelected: boolean,
    choiceContent: string
  ) => {
    const outcomeLogic = this._getOutcomeLogic();
    if (isAlreadySelected) {
      // remove
      this.props.updateOutcomeDisplayLogic(this.props.question.question_id, {
        ...outcomeLogic,
        choiceOutcomesMapping: {
          ...outcomeLogic.choiceOutcomesMapping,
          [choiceContent]: removeIfExist(
            nonnullArray(outcomeLogic.choiceOutcomesMapping[choiceContent]),
            outcomeID
          )
        }
      });
    } else {
      // add
      this.props.updateOutcomeDisplayLogic(this.props.question.question_id, {
        ...outcomeLogic,
        choiceOutcomesMapping: {
          ...outcomeLogic.choiceOutcomesMapping,
          [choiceContent]: insertIfNonExist(
            nonnullArray(outcomeLogic.choiceOutcomesMapping[choiceContent]),
            outcomeID
          )
        }
      });
    }
  };

  _renderChoiceOutcomeMap = (choiceDisplay: string, choiceContent: string) => {
    const logic = this.props.question.outcomeDisplayLogic;
    const selectedOutcome =
      logic &&
      logic.choiceOutcomesMapping &&
      logic.choiceOutcomesMapping[choiceContent];
    return (
      <UICoreBox
        verticalPadding="xm"
        horizontalPadding="xm"
        shape="rounded"
        borderColor="#ECECEC"
        marginTop="sm"
      >
        <UICoreText color="black">If user choose:</UICoreText>
        <UICoreBox shape="rounded" color="lightGrey" marginTop="xm">
          <UICoreBox horizontalPadding="sm" verticalMargin="xm">
            <UICoreText>{choiceDisplay}</UICoreText>
          </UICoreBox>
        </UICoreBox>
        <UICoreBox paddingTop="xm">
          <UICoreText color="black">Then map to outcome(s):</UICoreText>
        </UICoreBox>
        <UICoreBox paddingTop="xm">
          <UICoreMultiSelectDropdown
            choices={nonnullArray(this.props.outcomes).map(outcome => {
              return {
                value: outcome.id,
                display: outcome.title
              };
            })}
            selected={nonnullArray(selectedOutcome)}
            onItemClick={(outcomeID, isAlreadySelected) => {
              this._handleItemClick(
                outcomeID,
                isAlreadySelected,
                choiceContent
              );
            }}
            onItemDeleteClick={outcomeID => {
              this._handleItemClick(
                outcomeID,
                true, //isAlreadySelected
                choiceContent
              );
            }}
            nullState={{
              title: "You don't have any outcome yet. Go create one!",
              description:
                "  you will be redirected to outcome page to create an outcome."
            }}
            dropdownFooterButton={{
              buttonText: isEmptyArray(this.props.outcomes)
                ? "create outcome"
                : "create another outcome",
              onClick: () => {
                this.props.updateOutcomePage({
                  ...toUnNullable(this.props.outcomePageConfig),
                  outcomeConfig: {
                    ...toUnNullable(
                      this.props.outcomePageConfig &&
                        this.props.outcomePageConfig.outcomeConfig
                    ),
                    outcomes: [
                      ...toArray(
                        this.props.outcomePageConfig &&
                          this.props.outcomePageConfig.outcomeConfig &&
                          this.props.outcomePageConfig.outcomeConfig.outcomes
                      ),
                      {
                        id: uniqid.time(),
                        title: "",
                        media: ""
                      }
                    ]
                  }
                });
                this.props.set_current_editing_question(
                  QuestionTypeEnum.OutcomePage
                );
              }
            }}
          />
        </UICoreBox>
      </UICoreBox>
    );
  };

  _hasOutcomeMappingLogic = () => {
    return (
      this.props.question &&
      this.props.question.outcomeDisplayLogic &&
      this.props.question.outcomeDisplayLogic.choiceOutcomesMapping &&
      isNonEmptyArray(
        Object.keys(
          this.props.question.outcomeDisplayLogic.choiceOutcomesMapping
        )
      )
    );
  };

  _renderOutcomeMappingButton = () => {
    if (this._hasOutcomeMappingLogic()) {
      return (
        <UICoreBox onClick={_ => this.setState({ showModal: true })}>
          <UICoreButton key={0} color="blue" size="sm">
            {" Update "}
          </UICoreButton>
        </UICoreBox>
      );
    } else {
      return (
        <UICoreBox onClick={_ => this.setState({ showModal: true })}>
          <UICoreButton key={1} color="lightBlue" size="sm">
            {" Add "}
          </UICoreButton>
        </UICoreBox>
      );
    }
  };

  render() {
    // $FlowFixMe
    const questionsConfigs = new formConfiguration().types;
    const questionConfig = questionsConfigs[this.props.question.type];
    const choiceContent =
      questionConfig.getAnswerChoices &&
      questionConfig.getAnswerChoices(this.props.question);
    return [
      this._renderOutcomeMappingButton(),
      this.state.showModal && (
        <UICoreModal
          size="md"
          dismissText="Save"
          onDismiss={_ => {
            this.props.saveToBackend();
            this.setState({ showModal: false });
          }}
          header="Outcome Mapping"
          body={
            <Scrollbars autoHeight autoHeightMax="90vh">
              <UICoreBox paddingLeft="sm" paddingRight="sm" paddingBottom="sm">
                <UICoreBox paddingTop="sm">
                  <UICoreText size="xs">
                    Outcome mapping maps user choices to outcomes displayed at
                    the end of form. Outcome(s) that have majority of user’s
                    choices mapped to will be displayed.
                  </UICoreText>
                </UICoreBox>
                <UICoreBox>
                  {nonnullArray(choiceContent).map(content => {
                    // $FlowFixMe
                    const questionsConfigs = new formConfiguration().types;
                    const questionConfig =
                      questionsConfigs[this.props.question.type];
                    if (
                      this.props.question &&
                      this.props.question.type === "Rating" &&
                      content.display === "None"
                    ) {
                      return;
                    } else {
                      return this._renderChoiceOutcomeMap(
                        content.display,
                        content.value
                      );
                    }
                  })}
                </UICoreBox>
              </UICoreBox>
            </Scrollbars>
          }
        />
      )
    ];
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    outcomes: getOutcomes(state.Question),
    outcomePageConfig: getOutcomePageContentConfig(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveToBackend: () => {
      dispatch(saveToBackend());
    },
    updateOutcomeDisplayLogic: (
      questionID: questionIDType,
      updatedOutcomeDisplayLogic: ToutcomeDisplayLogic
    ) => {
      dispatch(
        update_outcome_display_logic(questionID, updatedOutcomeDisplayLogic)
      );
    },
    updateOutcomePage: (pageConfig: TGenericContentConfig) => {
      dispatch(update_outcome_page(pageConfig));
    },
    set_current_editing_question: pageNumber =>
      dispatch(set_current_editing_question(pageNumber))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutcomeMapping);
