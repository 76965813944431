// @flow
import * as React from "react";
import { UICoreText, UICoreTextArea, UICoreTextEditable } from "../../UICore";
import { type TGenericFormComponentPro } from "../type";
type State = {||};
type GFC_HeaderProps = {|
  ...TGenericFormComponentPro,
  isSubheader: boolean
|};
class GFC_Header extends React.Component<GFC_HeaderProps, State> {
  constructor(props: GFC_HeaderProps) {
    super(props);
    // this.state = {};
  }

  _getText = () => {
    if (this.props.isSubheader) {
      return this.props.config.header && this.props.config.header.description
        ? this.props.config.header.description
        : "";
    } else {
      return this.props.config.header && this.props.config.header.title
        ? this.props.config.header.title
        : "";
    }
  };

  _getSize = () => {
    return this.props.isSubheader ? "md" : "xl";
  };

  _getWeight = () => {
    return this.props.isSubheader ? "normal" : "bold";
  };

  render() {
    if (this.props.isEditMode) {
      return (
        <UICoreTextEditable
          fontFamily={this.props.styles && this.props.styles.fontFamily}
          placeholderColor={this.props.answerSecondaryColor}
          inputBoxColor={this.props.answerSecondaryColor}
          allowMultipleLines={true}
          overflow="wrap"
          size={this._getSize()}
          weight={this._getWeight()}
          width="100%"
          autoSelect={true}
          hexColor={this.props.styles.answer}
          alignment="center"
          placeholder={
            this.props.isSubheader
              ? "Type your subheading here..."
              : "Type your heading here..."
          }
          onBlur={() => {
            this.props.onUpdate &&
              this.props.onUpdate(
                {
                  ...this.props.config
                },
                false
              );
          }}
          onInput={(_, text) => {
            if (this.props.isSubheader) {
              this.props.onUpdate &&
                this.props.onUpdate(
                  {
                    ...this.props.config,
                    header: {
                      ...this.props.config.header,
                      description: text
                    }
                  },
                  true
                );
            } else {
              this.props.onUpdate &&
                this.props.onUpdate(
                  {
                    ...this.props.config,
                    header: {
                      ...this.props.config.header,
                      title: text
                    }
                  },
                  true
                );
            }
          }}
        >
          {this._getText()}
        </UICoreTextEditable>
      );
    } else {
      const text = this._getText();
      return text ? (
        <UICoreText
          alignment="center"
          parseLink={true}
          overflow="wrap"
          size={this._getSize()}
          weight={this._getWeight()}
          hexColor={this.props.styles.answer}
          fontFamily={this.props.styles && this.props.styles.fontFamily}
        >
          {text}
        </UICoreText>
      ) : (
        <div></div>
      );
    }
  }
}
export default GFC_Header;
