//@flow
import * as React from "react";
import { UICoreBox, UICoreText, UICoreFlyOut } from "../index";
import { type Props as UICoreTextProps } from "../UICoreText";
import CursorManager from "./CursorManager";
import * as InputManager from "./InputManager";
import "./style.css";
import {
  delayOneCycle,
  safeGet,
  filterNull,
  isNonEmptyString,
  executeCallbackSafely,
  isNullOrUndefined
} from "../../../Library/Util";
import {
  type tagInputStringRepresentationType,
  type tagInputTagMapType,
  type cursorUpdateEventEnumType,
  type tagInputRenderTagContentMapType,
  tagInputNodeEnum,
  cursorUpdateEventEnum
} from "../../../FlowTypes/UICoreTagInputTypes";
import {
  UICoreListItemTypeEnum,
  type UICoreListItemType
} from "../../../FlowTypes/UICoreTypes/UICoreListType";
import UICoreTagInputTag from "./UICoreTagInputTag";
import UICoreList from "../UICoreList";
import { Scrollbars } from "react-custom-scrollbars";

const kZeroWidthNoBreakSpace = "\uFEFF";
const kAtKeyCode = 64;
const kShiftKeyCode = 16;
const kDeleteKeyCode = 46;
const kBackspaceKeyCode = 8;
const k2KeyCode = 50;
const kNewLine = "\n";

type Props = {|
  textStyleProps: UICoreTextProps,
  tagTextStyle?: UICoreTextProps,
  tagHexColor?: string,
  input: tagInputStringRepresentationType,
  tagOptionGroups: Array<{|
    header?: string,
    tags: tagInputTagMapType
  |}>,
  width?: string,
  className?: string,
  contentEditableClassName?: string,
  placeHolder?: string,
  dropdownDirection?: "up" | "down",
  onInputUpdate: tagInputStringRepresentationType => void,
  onInputBlur?: () => void,
  onDropdownOptionClick?: () => void
|};
type State = {|
  showDropdown: boolean
|};

class Editor extends React.Component<Props, State> {
  _input: ?HTMLElement = null;

  _placeholderNode: ?HTMLElement = null;

  _cursorUpdateEvent: cursorUpdateEventEnumType = cursorUpdateEventEnum.unknown;

  _tagInputWrapperNode: ?HTMLElement = null;

  _cursorManager = new CursorManager();

  constructor(props: Props) {
    super(props);
  }

  state = {
    showDropdown: false
  };

  _isNonEmptyInput = (title: ?string): boolean => {
    if (isNullOrUndefined(title)) {
      return false;
    }
    return (
      String(title)
        .split("")
        .filter(char => char !== kZeroWidthNoBreakSpace && char !== kNewLine)
        .join("").length > 0
    );
  };

  shouldComponentUpdate(nextProps: Props) {
    // update placeholder since we can't reply on the component re-render
    if (this._isNonEmptyInput(nextProps.input) && this._placeholderNode) {
      this._placeholderNode.hidden = true;
    } else if (this._placeholderNode) {
      this._placeholderNode.hidden = false;
    }
    // prevent component update on keyup, otherwise, user won't be able to type chinese.
    switch (this._cursorUpdateEvent) {
      case cursorUpdateEventEnum.keyUp:
        // text style update should still go through
        if (
          nextProps.textStyleProps.color !== this.props.textStyleProps.color ||
          nextProps.textStyleProps.hexColor !==
            this.props.textStyleProps.hexColor ||
          nextProps.textStyleProps.fontFamily !==
            this.props.textStyleProps.fontFamily
        ) {
          return true;
        }
        return false;
      case cursorUpdateEventEnum.unknown:
      case cursorUpdateEventEnum.dropdownTrigger:
      case cursorUpdateEventEnum.tagClick:
      case cursorUpdateEventEnum.tagDelete:
      case cursorUpdateEventEnum.textDelete:
        return true;

      default:
        return true;
    }
  }

  componentDidMount() {
    this.componentDidChange();
    this._cursorManager.registerCursorListener();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this.componentDidChange();
    if (this._cursorUpdateEvent === cursorUpdateEventEnum.tagDelete) {
      window.blur();
    } else if (this._hasInputChanged(prevProps)) {
      // we should only reset the cursor if input changed
      this._cursorManager.resetCursorOffset();
    }
  }

  componentDidChange() {
    if (this._input) {
      this._cursorManager.updateParentElement(this._input);
    }
  }

  componentWillUnmount() {
    this._cursorManager.unregisterCursorListner();
  }

  _hasInputChanged = (prevProps: Props) => {
    return prevProps.input !== this.props.input;
  };

  _handleDropdownItemClick = (tagID: string) => {
    executeCallbackSafely(this.props.onDropdownOptionClick);
    // need to reset the trigger state.
    // If not, it won't trigger new dropdown.
    InputManager.resetDropdownTriggerDetectorState();
    this._cursorUpdateEvent = cursorUpdateEventEnum.tagClick;
    this.props.onInputUpdate(
      InputManager.getUpdatedInputForTagUpdate(
        this._cursorManager,
        this.props.input,
        tagID
      )
    );

    this._cursorManager.updateCursorState(-1, cursorUpdateEventEnum.tagClick);

    this.setState({
      showDropdown: false
    });
  };

  _handleKeyUp = (e: SyntheticKeyboardEvent<HTMLSpanElement>) => {
    e.preventDefault();
    if (this._input) {
      this.props.onInputUpdate(
        InputManager.getUpdatedInputForTextUpdate(this.props.input, this._input)
      );
    }
    InputManager.keyDownEventHandler(e, this.props.input);
    this._cursorManager.updateCursorState(
      e.keyCode,
      cursorUpdateEventEnum.keyUp
    );
    if (InputManager.shouldShowDropdownMenu(this.props.input)) {
      this._cursorUpdateEvent = cursorUpdateEventEnum.dropdownTrigger;
      this.setState({ showDropdown: true });
    } else if (InputManager.shouldHideDropdownMenu(this.props.input)) {
      this._cursorUpdateEvent = cursorUpdateEventEnum.dropdownTrigger;
      this.setState({ showDropdown: false });
    } else if (
      e.keyCode === kDeleteKeyCode ||
      e.keyCode === kBackspaceKeyCode
    ) {
      this._cursorUpdateEvent = cursorUpdateEventEnum.textDelete;
    } else {
      this._cursorUpdateEvent = cursorUpdateEventEnum.keyUp;
    }
  };

  _handleTagDelete = (nodeOffset: number) => {
    this.props.onInputUpdate(
      InputManager.getUpdatedInputForTagDelete(this.props.input, nodeOffset)
    );
    this._cursorManager.updateCursorState(-1, cursorUpdateEventEnum.tagDelete);
    this._cursorUpdateEvent = cursorUpdateEventEnum.tagDelete;
  };

  _getListItems = (): Array<UICoreListItemType> => {
    return this.props.tagOptionGroups.reduce((prev, optionGroup) => {
      return filterNull([
        ...prev,
        optionGroup.header
          ? {
              title: optionGroup.header,
              itemType: UICoreListItemTypeEnum.header
            }
          : null,
        ...InputManager.getTagContentListFromMap(optionGroup.tags).map(
          (tagItem, index) => {
            return {
              title: tagItem.content,
              extra: tagItem
            };
          }
        )
      ]);
    }, []);
  };

  _renderContent = () => {
    return filterNull(
      InputManager.convertStringRep2Array(this.props.input).map(
        (token, index) => {
          if (token.type === tagInputNodeEnum.tag) {
            const tagContent =
              InputManager.getTagIdContentMapFromTagOptionGroups(
                this.props.tagOptionGroups
              )[token.content];
            return tagContent ? (
              <UICoreTagInputTag
                tagHexColor={this.props.tagHexColor}
                tagTextStyle={this.props.tagTextStyle}
                key={index}
                tag={
                  InputManager.getTagIdContentMapFromTagOptionGroups(
                    this.props.tagOptionGroups
                  )[token.content]
                }
                onTagDelete={() => this._handleTagDelete(index)}
              />
            ) : null;
          } else {
            return (
              <span
                data-type={tagInputNodeEnum.string}
                data-content={token.content}
                key={index}
              >
                {token.content}
              </span>
            );
          }
        }
      )
    );
  };

  _handlePaste = (e: SyntheticInputEvent<HTMLDivElement>) => {
    var pastedText = "";
    if (window.clipboardData && window.clipboardData.getData) {
      // IE
      pastedText = window.clipboardData.getData("Text");
      // $FlowFixMe
    } else if (e.clipboardData && e.clipboardData.getData) {
      pastedText = e.clipboardData.getData("text/plain");
    }
    e.target.textContent = pastedText;
    e.preventDefault();
    return false;
  };

  _renderPlaceholderIfNecessary = () => {
    if (this.props.placeHolder) {
      return (
        <span
          ref={node => (this._placeholderNode = node)}
          style={{
            position: "absolute",
            left: "0px",
            top: "0px",
            opacity: 0.4,
            pointerEvents: "none"
          }}
        >
          <UICoreText>{this.props.placeHolder}</UICoreText>
        </span>
      );
    } else {
      return null;
    }
  };

  render() {
    return [
      <div
        ref={node => {
          this._tagInputWrapperNode = node;
        }}
        className={"UICoreTagInput " + String(this.props.className)}
        style={{ width: this.props.width, position: "relative" }}
      >
        {this._renderPlaceholderIfNecessary()}

        <UICoreText {...this.props.textStyleProps}>
          <span
            key={this.props.input}
            className={
              "UICoreTagInput-inputSpan " +
              String(this.props.contentEditableClassName)
            }
            onPaste={this._handlePaste}
            onKeyUp={this._handleKeyUp}
            contentEditable={true}
            style={{
              "white-space": "pre-wrap",
              display: "inline-block",
              width: "100%"
            }}
            ref={e => {
              this._input = e;
            }}
            onBlur={this.props.onInputBlur}
          >
            {isNonEmptyString(this.props.input) ? (
              this._renderContent()
            ) : (
              <span
                style={{
                  display: "block",
                  width: "100%"
                }}
                data-type={tagInputNodeEnum.string}
                data-content={kZeroWidthNoBreakSpace}
                key={0}
                onKeyUp={e => {
                  this.forceUpdate();
                }}
              >
                {kZeroWidthNoBreakSpace}
              </span>
            )}
          </span>
        </UICoreText>
      </div>,
      this.state.showDropdown && (
        <UICoreFlyOut
          size="xl"
          idealDirection={this.props.dropdownDirection || "down"}
          showCaret={false}
          onDismiss={_ => {
            this.setState({
              showDropdown: false
            });
          }}
          shouldFocus={false}
          anchor={this._tagInputWrapperNode}
          forceDirection={true}
        >
          <UICoreBox width="100%">
            <Scrollbars autoHeight autoHeightMax="250px">
              <UICoreList
                onItemClick={(e, item) =>
                  this._handleDropdownItemClick(item.id)
                }
                onItemMouseDown={(e, item) => e.preventDefault()}
                items={this._getListItems()}
              />
            </Scrollbars>
          </UICoreBox>
        </UICoreFlyOut>
      )
    ];
  }
}

type RenderProps = {|
  textStyleProps?: UICoreTextProps,
  input: tagInputStringRepresentationType,
  tags: tagInputRenderTagContentMapType
|};
type RenderState = {||};

class Render extends React.Component<RenderProps, RenderState> {
  render() {
    return (
      <UICoreText {...this.props.textStyleProps}>
        {InputManager.convertStringRep2TagFilledString(
          this.props.input,
          this.props.tags
        )}
      </UICoreText>
    );
  }
}

export default {
  Editor: Editor,
  Render: Render
};
