import * as Sentry from "@sentry/browser";
import { getUserID, getUserName } from "./UserInfo";

export default function initSentry() {
  Sentry.init({
    dsn: "https://f6ee793a00f74ab19dc8bb882f2688c6@o380012.ingest.sentry.io/5205395",
    integrations: [Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  //Set user info
  // Sentry.configureScope(function (scope) {
  //   scope.setUser({ id: getUserID(), username: getUserName() });
  // });
}
